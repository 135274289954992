import { difference } from 'lodash-es';

import { acceptedCookieCategories, AVAILABLE_CATEGORIES } from './cookies';

// Enable script tags, that set cookies, according to user's consent
export function enableCookieScripts() {
  const blockScripts = document.body.dataset.blockScripts === 'true';

  const acceptedCategories = blockScripts
    ? acceptedCookieCategories()
    : AVAILABLE_CATEGORIES;

  acceptedCategories.forEach(function (group) {
    // Toggle text/javascript on script tags
    enableCookieGroup(group);

    // Show affected content blocks
    setElementVisibility(contentBlockSelector(group), true);
    setElementVisibility(cookiesRequiredSelector(group), false);
  });

  const rejectedGroups = difference(AVAILABLE_CATEGORIES, acceptedCategories);
  rejectedGroups.forEach(function (group) {
    // Hide content blocks and display "cookies required" notice
    setElementVisibility(contentBlockSelector(group), false);
    setElementVisibility(cookiesRequiredSelector(group), true);
  });
}

export function enableCookieGroup(type) {
  enableScriptTags(type);
  enableIframes(type);
  let cookiesEnabledEvent = new CustomEvent('cookies-enabled');
  document.dispatchEvent(cookiesEnabledEvent);
}

function enableScriptTags(type) {
  // eslint-disable-next-line quotes
  let selector = "script[data-cookie-type='" + type + "'][type='text/plain']";

  document.querySelectorAll(selector).forEach((element) => {
    let newElement = element.cloneNode(true);

    newElement.setAttribute('type', 'text/javascript');

    element.parentNode.insertBefore(newElement, element);

    element.remove();
  });
}

function enableIframes(type) {
  // eslint-disable-next-line quotes
  let selector = "iframe[data-cookie-type='" + type + "']";
  document.querySelectorAll(selector).forEach((element) => {
    let dataSrc = element.getAttribute('data-src');
    if (dataSrc) {
      element.setAttribute('src', dataSrc);
    }
  });
}

function contentBlockSelector(type) {
  return '[data-cookie-block="' + type + '"]';
}

function cookiesRequiredSelector(type) {
  return '[data-cookies-required="' + type + '"]';
}

function setElementVisibility(selector, visible) {
  let elements = document.querySelectorAll(selector);
  elements.forEach(function (element) {
    element.style.display = visible ? '' : 'none';
  });
}
