const GTM_STORAGE_TYPES = {
  analytics: 'analytics_storage',
  marketing: 'ad_storage',
  preferences: 'personalization_storage',
};

const GTM_MARKETING_CONSENT_OPTIONS = ['ad_personalization', 'ad_user_data'];

const DEFAULT_STORAGE_SETTINGS = {
  analytics_storage: 'denied',
  ad_storage: 'denied',
  personalization_storage: 'denied',
  ad_personalization: 'denied',
  ad_user_data: 'denied',
};

export function setAcceptedGTMStorageCategories(categories, event = 'update') {
  let storageSettings = { ...DEFAULT_STORAGE_SETTINGS };

  categories.forEach((category) => {
    storageSettings[GTM_STORAGE_TYPES[category]] = 'granted';
  });

  GTM_MARKETING_CONSENT_OPTIONS.forEach((option) => {
    storageSettings[option] = storageSettings['ad_storage'];
  });

  gtag('consent', event, storageSettings);
  window.dataLayer.push({ event: 'cookie_consent_updated' });
}

export function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}
