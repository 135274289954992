import Cookies from 'js-cookie';
import { difference } from 'lodash-es';
import { setAcceptedGTMStorageCategories } from './gtm';
import { enableCookieScripts } from './script-tags';

export const AVAILABLE_CATEGORIES = ['analytics', 'marketing', 'preferences'];
const COOKIE_NAME = '_ttCookiePermissions';
const NECESSARY_COOKIES = ['_tt_session'];
const TEAMTAILOR_COOKIES = ['_ttAnalytics', 'referrer', 'ti'];

export function acceptAllCookies(isIframe) {
  acceptCookies(AVAILABLE_CATEGORIES, isIframe);
}

export function disableAllCookies(isIframe) {
  acceptCookies([], isIframe);
}

export function acceptedCookieCategories() {
  return Cookies.get(COOKIE_NAME)?.split(',') || [];
}

export function hasAcceptedCookieCategory(category) {
  return acceptedCookieCategories().find((c) => c === category);
}

export function hasMadeCookieChoice() {
  return Cookies.get(COOKIE_NAME) !== undefined;
}

export function acceptCookies(categories, isIframe) {
  // Check if any cookie category has been retracted
  const hasRetractedCookieCategories =
    difference(acceptedCookieCategories(), categories).length > 0;

  setAcceptedGTMStorageCategories(categories);

  // If categories has been retracted, we need to clear all cookies and reload page in order to not
  // continue running script tags that are not allowed to run
  if (hasRetractedCookieCategories) {
    const allCookies = Object.keys(Cookies.get());
    const cookiesToBeRemoved = allCookies.filter((cookie) => {
      return ![...NECESSARY_COOKIES, COOKIE_NAME].includes(cookie);
    });

    cookiesToBeRemoved.forEach((cookie) => {
      // If Teamtailor cookie, remove no matter which domain
      if (TEAMTAILOR_COOKIES.includes(cookie)) {
        Cookies.remove(cookie);
      } else {
        Cookies.remove(cookie, { domain: window.location.hostname });
      }
    });
  }
  fetch('/cookie-policy/accept', {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    body: JSON.stringify({
      cookie_policy: {
        visitor_uuid: window.visitor_uuid,
        referrer: window.referrer,
        categories: categories.join(','),
        same_site: isIframe ? 'None' : undefined,
      },
    }),
  }).then(() => {
    enableCookieScripts();
    if (hasRetractedCookieCategories) {
      window.location.reload();
    } else {
      document.dispatchEvent(new Event('teamtailor:cookies-changed'));
    }
  });
}
