import { enableCookieScripts } from '../utils/script-tags';

if (navigator.cookieEnabled) {
  // Enable cookie scripts as soon as possible
  enableCookieScripts();

  document.addEventListener('turbo:load', () => {
    // Also enable them after each Turbo visit since it replaces the contents of <body>
    enableCookieScripts();
  });
}
